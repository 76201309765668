export default {
  label: 'Cart Settings',
  name: 'cart',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
      defaultValue: 'My Cart',
    },
    {
      label: 'Bulk ordering Settings',
      name: 'bulkOrdering',
      component: 'group',
      fields: [
        {
          name: 'maxQty',
          component: 'number',
          label: 'Cart max quantity',
          description:
            'Max quantity of products in cart. Set ignored products below',
        },
        {
          name: 'errorMessage',
          component: 'markdown',
          label: 'Pdp message',
          description: 'Message when max quantity is already met on PDP',
        },
        {
          name: 'itemMessage',
          component: 'markdown',
          label: 'Cart message',
          description: 'Message when max quantity is already met in cart item',
        },
        {
          name: 'ignoredProducts',
          label: 'Ignored products',
          component: 'group-list',
          description:
            'Products ignored from max quantity and bulk ordering limitations',
          itemProps: {
            label: '{{item.product.handle}}' || 'New Product',
          },
          fields: [
            {
              name: 'product',
              component: 'productSearch',
              label: 'Product',
            },
          ],
          defaultValue: [
            { product: { handle: 'byob-charge' } },
            { product: { handle: 'parcel-protection' } },
            { product: { handle: 'brumate-e-gift-card' } },
          ],
        },
      ],
      defaultValue: {
        maxQty: 150,
        errorMessage:
          'Please contact [bulkorders@brumate.com](mailto:bulkorders@brumate.com) for orders over 150.',
        itemMessage:
          'Please contact [bulkorders@brumate.com](mailto:bulkorders@brumate.com) for orders over 150.',
      },
    },
    {
      name: 'mixedCartLock',
      component: 'group',
      label: 'Mixed Cart Lock',
      fields: [
        {
          name: 'enabled',
          label: 'Enable Mixed Cart Lock',
          component: 'toggle',
          toggleLabels: {
            true: 'Enabled',
            false: 'Disabled',
          },
        },
        {
          name: 'collection',
          label: 'Collection',
          component: 'collections',
          description:
            'Collection defining the products that must be purcased individually',
        },
        {
          name: 'products',
          label: 'Product List',
          component: 'list',
          description:
            'Products that must be purchased individually, use in replacement of the collection option above',
          field: {
            component: 'productSearch',
            label: 'Product',
          },
        },
        {
          name: 'message',
          label: 'Message',
          component: 'markdown',
          description:
            'Message displayed when a customer cart has mixed products',
        },
      ],
    },
    {
      label: 'Cart empty Settings',
      name: 'empty',
      component: 'group',
      fields: [
        {
          label: 'Message',
          name: 'message',
          component: 'text',
          defaultValue: 'Your cart is empty',
        },
        {
          name: 'ctas',
          label: 'Links',
          component: 'group-list',
          itemProps: {
            label: '{{item.cta.text}}',
          },
          defaultItem: {
            cta: {
              text: 'Shop Best Sellers',
              url: '/collections/best-sellers',
            },
          },
          fields: [
            {
              name: 'cta',
              component: 'link',
              label: 'Link',
            },
          ],
          defaultValue: [
            {
              cta: {
                text: 'Shop Best Sellers',
                url: '/collections/best-sellers',
              },
            },
            {
              cta: {
                text: 'Shop New Arrivals',
                url: '/collections/new-releases',
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Free shipping meter Settings',
      name: 'freeShipping',
      component: 'group',
      fields: [
        {
          name: 'enabled',
          label: 'Enable free shipping meter',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          name: 'spend',
          component: 'number',
          label: 'Qualifying spend (USD)',
        },
        {
          name: 'message',
          component: 'text',
          label: 'Message',
          description: 'Denote placement of the remaining spend with a "$"',
        },
        {
          name: 'messageQualified',
          component: 'text',
          label: 'Qualified message',
        },
      ],
      defaultValue: {
        enabled: true,
        spend: 50,
        message: `You're only $ away from free shipping!`,
        messageQualified: `You're qualified for free shipping!`,
      },
    },
    {
      label: 'Upsell Settings',
      name: 'upsell',
      component: 'group',
      fields: [
        {
          name: 'enabled',
          label: 'Enable upsell',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          name: 'message',
          component: 'text',
          label: 'Message w/ active cart',
        },
        {
          name: 'messageEmpty',
          component: 'text',
          label: 'Message w/ empty cart',
        },
        {
          name: 'limit',
          component: 'number',
          label: 'Number of upsell products',
        },
      ],
      defaultValue: {
        enabled: true,
        message: 'Complete your collection with these favorites',
        messageEmpty: 'Start out with these fan favorites!',
        limit: 4,
      },
    },
  ],
};